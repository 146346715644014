.scroll-top-button{
  position: fixed;
  bottom: 40px;
  right: 40px;
}
.select-field{
  width:200px;
}
.tableRow{
  cursor: pointer;
  &:hover{
    background-color: rgba(0, 0, 0, 0.15) !important;
  }
}
.padding-10-20{
  padding: 10px 20px;
}
.option{
  cursor: pointer;
}
.height-100{
  height: 80vh;
}
.width-100{
 width: 100%;
}
.modal{
  display: flex;
  align-items: center;
  justify-content: center;
}
.status{
  color: #fff;
  padding: 5px 10px;
  text-align: center;
}
.block{
  background: #a81818;
}
.active{
  background: #0e8e0e;
}
.padding-5-10{
  padding: 5px 10px;
}
.padding-10-20{
  padding: 10px 20px;
}
.login-form
{
  justify-content: center;
  min-height: 90vh;
}
.button-block{
  width: 100%;
}
.login-background
{
  justify-content: center;
  min-height: 30vh;
  padding: 50px;
}

a{
  text-decoration: none;
  color: black;
  transition: .4s;
  &:hover{
    transition: .4s;
    color: darkblue;
  }
}
.logo{
  height: 250px;
  width: auto;
}
.flex{
  display: flex;
}
.col{
  flex-direction: column;
}
.row{
  flex-direction: row;
}
.justify-content-center{
  justify-content: center;
}
.justify-content-start{
  justify-content:flex-start;
}
.justify-content-end{
  justify-content: flex-end;
}
.space-between{
  justify-content: space-between;
}
.space-around{
  justify-content: space-around;
}
.align-items-center{
  align-items: center;
}
.align-items-start{
  align-items: flex-start;
}
.align-items-end{
  align-items: flex-end;
}
.align-self-end{
  align-self: flex-end;
}
.align-self-start{
  align-self: flex-start;
}
.align-self-center{
  align-self: center;
}